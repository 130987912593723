import React from "react";
import Layout from "components/layout";
import ChangePassword from "components/userAccount/change-password";

function index() {
  return (
    <Layout>
      <ChangePassword/>
    </Layout>
  );
}

export default index;
