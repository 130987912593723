import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { useForm } from "react-hook-form";
import { useChangePassword } from "hooks/Forms/useChangePassword";
import formCommonPostData from "utils/form-common-post-data";

export default function ChangePassword() {
  const [urlParam, setUrlParam] = useState("");
  const [emailParam, setEmailParam] = useState("");
  const [formStatus, setForStatus] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorToken, setErrorToken] = useState(false);
  const { mutateAsync } = useChangePassword();
  const [password, setPassword] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var token = url.searchParams.get("token");
    var email = url.searchParams.get("email");
    if (!token || !email) {
      navigate("/");
      return;
    }
    setUrlParam(token);
    setEmailParam(email);
  }, []);

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setErrorStatus(true);
      return false;
    }
    const postData1 = {
      password: data.password,
      password_confirmation: data.confirmPassword,
      email: emailParam,
      token: urlParam,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((response) => {
      if (response.success === 1) {
        reset();
        setForStatus(true);
        return navigate("/");
      }
      if (response.message === "Invalid Token") {
        // reset();
        setErrorToken(true);
        // return navigate("/");
      }
      // else {
      //   setErrorStatus(true);
      // }
    });
  };

  return (
    <div className="w-full bg-[#272727] py-20 xl:px-40 text-white font-primary">
      <div className="w-11/12 xl:w-full mx-auto flex flex-col lg:flex-row gap-10 lg:gap-16 xl:gap-56 ">
        <div>
          <h2 className="mb-5 ml-[60px]">
            <Link to="/">
              <img
                src="https://skins.tomedes.com/frontend/images/tomedes-logo.svg"
                alt=""
                className="w-44"
              />
            </Link>
          </h2>
          <div className="w-[300px] p-5 bg-black rounded-md shadow">
            <h2 className="custom-bg-h1 font-riot font-medium text-[28px] leading-10 mt-3">
              Change Password
            </h2>
            <p className="text-[15px] text-center mt-4 mb-5">
              Enter your new password below. You will receive an email.
            </p>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                autoComplete="off"
                className="px-2 py-2 bg-white rounded-sm w-full mb-5 text-black"
                {...register("password", {
                  required: true,
                  pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/,
                  onBlur: (e) => setPassword(e.target.value),
                })}
              />
              {errors.password && (
                <span className="text-red pb-3 -mt-3 block">
                  Password should contain one Capital Letter, one Small Letter,
                  and the number of characters should be between 6 to 15.
                </span>
              )}
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
                autoComplete="off"
                className="px-2 py-2 bg-white rounded-sm w-full mb-5 text-black"
                {...register("confirmPassword", {
                  required: true,
                  onChange: (e) =>
                    password === e.target.value
                      ? setErrorStatus(false)
                      : setErrorStatus(true),
                })}
              />
              {formStatus ? (
                <span className="text-[#96D149] text-sm">
                  Password Changed! <br />
                </span>
              ) : (
                <></>
              )}
              {errorStatus ? (
                <span className="text-red text-sm inline-block pb-3 -mt-3">
                  Password and confirm password should match! <br />
                </span>
              ) : (
                <></>
              )}
              {errorToken ? (
                <span className="text-red text-sm py-2 block">
                  TOKEN EXPIRED, Request password again <br />
                </span>
              ) : (
                <></>
              )}
              <input type="hidden" id="zc_gad" name="zc_gad" value="" />
              <button className="bg-[#2664E4] py-2 flex justify-center items-center rounded-sm w-full mb-1">
                Submit
              </button>
            </form>
          </div>
        </div>
        <div>
          <h2 className="font-riot text-24 sm:text-[34px] font-medium mb-16 bg-[#0959ff80] inline-block">
            Frequently Asked Questions
          </h2>
          <div className="flex flex-col gap-9">
            <div>
              <div className="flex gap-1 items-center mb-6">
                <img
                  src="https://skins.tomedes.com/frontend/images/question_icon.svg"
                  alt=""
                />
                <h3 className="text-24 font-normal">Why should I trust you?</h3>
              </div>
              <p className="text-17 font-light leading-7">
                We serve business customers from all over the globe for 8+
                years, small businesses and Fortune 500 companies alike. If you
                want to be sure, contact us for a free of charge sample.
              </p>
            </div>
            <div>
              <div className="flex gap-1 items-center mb-6">
                <img
                  src="https://skins.tomedes.com/frontend/images/question_icon.svg"
                  alt=""
                />
                <h3 className="text-24 font-normal">
                  How can I be sure that I will receive my translation?
                </h3>
              </div>
              <p className="text-17 font-light leading-7">
                Dozens of translation projects are delivered to our customers
                each day. And of course we are at your service 24/7.
              </p>
            </div>
            <div>
              <div className="flex gap-1 items-center mb-6">
                <img
                  src="https://skins.tomedes.com/frontend/images/question_icon.svg"
                  alt=""
                />
                <h3 className="text-24 font-normal">
                  How do I retrieve my completed translation?
                </h3>
              </div>
              <p className="text-17 font-light leading-7">
                Upon translation completion, you will receive an email
                notification from the Tomedes team. The translated document will
                be waiting for you on your account page on tomedes.com.
              </p>
            </div>
            <div>
              <div className="flex gap-1 items-center mb-6">
                <img
                  src="https://skins.tomedes.com/frontend/images/question_icon.svg"
                  alt=""
                />
                <h3 className="text-24 font-normal">
                  Why do I need to pay in advance?
                </h3>
              </div>
              <p className="text-17 font-light leading-7">
                Truth be told, charging payment in advance helps us keep our
                costs lower and offer you attractive prices. Yet, Feel free to
                contact us if this option doesn't suit you.
              </p>
            </div>
            <div>
              <div className="flex gap-1 items-center mb-6">
                <img
                  src="https://skins.tomedes.com/frontend/images/question_icon.svg"
                  alt=""
                />
                <h3 className="text-24 font-normal">
                  What are my payment options?
                </h3>
              </div>
              <p className="text-17 font-light leading-7">
                We accept checks, bank transfers, credit cards, Skrill & PayPal
                transactions (you can use funds in your PayPal account or send
                payment using a credit card). If you have a specific request,
                feel free to contact us.
              </p>
            </div>
            <div>
              <div className="flex gap-1 items-center mb-6">
                <img
                  src="https://skins.tomedes.com/frontend/images/question_icon.svg"
                  alt=""
                />
                <h3 className="text-24 font-normal">
                  Why can't I order translation with proofreading?
                </h3>
              </div>
              <p className="text-17 font-light leading-7">
                Quality assurance is an inherent part of our job process. We
                consider QA measures, such as proofreading, to be our
                responsibility and believe you shouldn't be charged any extra
                payment for them. An expert from Tomedes team reviews every
                translation and makes sure it's only delivered in highest
                quality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
